<template>
  <div class="login-form">
    <div class="title">{{$t('MerchantLogin')}}</div>
    <div class="form-item">
      <div class="ipt">
        <input type="text"
               :placeholder="$t('EnterpriseAccount')"
               autocomplete="off"
               v-model.trim="userAccount"
               @keyup.enter="onLogin"/>
      </div>
    </div>
    <div class="form-item">
      <div class="ipt">
        <input type="password"
               :placeholder="$t('Password')"
               autocomplete="off"
               v-model.trim="password"
               @keyup.enter="onLogin"/>
      </div>
    </div>
    <a class="btn-submit" href="javascript:;" :disabled="disableSubmit" @click="onLogin">{{$t('login')}}</a>
    <router-link class="btn-registered" :to="{name:'AdminRegistered'}">{{$t('registered')}}</router-link>
  </div>
</template>
<script>
  export default {
    name: 'AdminLogin',
    i18n: {
      messages: {
        'zh-cn': {
          registered: '立即注册，免费试用'
        },
        'zh-hk': {
          registered: '立即註冊，免費試用'
        },
        'en': {
          registered: 'Sign Up Now'
        }
      }
    },
    data() {
      return {
        userAccount: '',
        password: '',
        redirect: ''
      };
    },
    computed: {
      disableSubmit: function () {
        return !this.userAccount || !this.password;
      }
    },
    mounted() {
      this.redirect = this.$route.query.redirect || '';
      document.querySelector('html').classList.add('bg');

      if (this.$route.query.invalidToken === '1') {
        this.$message.error('帳戶閒置或出現重覆登入，請再重新登入！');
      }
    },
    destroyed() {
      document.querySelector('html').classList.remove('bg');
    },
    created() {
    },
    methods: {
      setInputPwd() {
        this.showPassword = this.showPassword === 'password' ? 'text' : 'password';
      },
      onLogin() {
        if (this.userAccount && this.password) {
          const data = {
            userAccount: this.userAccount,
            password: this.password
          };

          const loading = this.$loading({text: 'Loading'});
          this.$store.dispatch('adminUser/login', data).then(res => {
            loading.close();
            // 更新信息
            this.$store.dispatch('adminUser/getAdminInfo');
            this.$store.dispatch('adminUser/getRoleList');
            this.$store.dispatch('adminUser/getMenuList').then(res => {
              // 如果是root用户则进入基本信息版面，否则进入第一个菜单版面
              if (this.$store.getters.roleType === 'root') {
                this.$router.replace(this.redirect ? {path: this.redirect} : {name: 'AdminMy'});
              } else {
                this.$router.replace({path: this.$store.getters.menuUrl[0]});
              }
            });
          }).catch(() => {
            loading.close();
          });
        }
      }
    }
  };
</script>
<style scoped lang="less">
  @import '../../../assets/style/variable';
  .login-form{
    width:440px;margin:60px auto;padding:0 30px 40px;background-color:#fff;border-radius:8px;
    .title{padding:20px 0 24px;color:#000;font-size:24px;}
    .btn-showpwd{position:absolute;top:14px;right:20px;}
  }
  .form-item{
    margin-top:16px;
    .ipt{
      position:relative;height:48px;
      input{
        width:100%;height:48px;padding:0 12px;font-size:14px;border:1px solid #ddd;border-radius:6px;
        &:focus{border-color:#ff6f61;transition:border-color 0.6s;}
      }
    }
  }
  .btn-submit{display:block;margin-top:40px;line-height:44px;text-align:center;color:#fff;font-size:18px;background:#ff6f61;border-radius:8px;}
  .btn-registered{display:block;margin-top:16px;line-height:42px;text-align:center;color:#ff6f61;font-size:18px;border:1px solid #ff6f61;border-radius:8px;}
</style>
